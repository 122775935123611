import { isAfter, isBefore } from 'date-fns';
import { ScheduleWeek } from './schedule';

export const MCASSchedule: Record<string, ScheduleWeek> = {
    week1: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '9:50',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '9:55',
                endTime: '10:30',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '10:35',
                endTime: '11:10',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '11:15',
                endTime: '12:05',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 2,
            },
            {
                name: 'Lunch',
                blockType: 'lunch',
                startTime: '12:05',
                endTime: '12:40',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'D Block Async',
                blockType: 'd',
                startTime: '12:40',
                endTime: '1:15',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'E Block Async',
                blockType: 'e',
                startTime: '1:20',
                endTime: '1:55',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block Async',
                blockType: 'f',
                startTime: '2:00',
                endTime: '2:35',
                isLunch: false,
                async: true,
                length: '25 minutes',
                blockNumber: 2,
            },
            {
                name: 'G Block Async',
                blockType: 'g',
                startTime: '2:40',
                endTime: '3:15',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
    week2: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'A Block Async',
                blockType: 'a',
                startTime: '9:15',
                endTime: '9:50',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'B Block Async',
                blockType: 'b',
                startTime: '9:55',
                endTime: '10:30',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'C Block Async',
                blockType: 'c',
                startTime: '10:35',
                endTime: '11:10',
                isLunch: false,
                async: true,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '11:15',
                endTime: '12:05',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 2,
            },
            {
                name: 'Lunch',
                blockType: 'lunch',
                startTime: '12:05',
                endTime: '12:40',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '12:40',
                endTime: '1:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '1:20',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '2:00',
                endTime: '2:35',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '2:40',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
};

const MCASStart = new Date(2021, 4, 3);
const MCASEnd = new Date(2021, 4, 15);

export function isMCASTime(date: Date): boolean {
    return isAfter(date, MCASStart) && isBefore(date, MCASEnd);
}
