import { ScheduleWeek } from './schedule';

const mttfLunches = [
    {
        name: '1st Lunch',
        startTime: '10:45',
        endTime: '11:20',
        length: '35 minutes',
        lunchId: 0,
    },
    {
        name: '2nd Lunch',
        startTime: '11:30',
        endTime: '12:05',
        length: '35 minutes',
        lunchId: 1,
    },
    {
        name: '3rd Lunch',
        startTime: '12:20',
        endTime: '12:55',
        length: '35 minutes',
        lunchId: 2,
    },
];

const wedLunches = [
    {
        name: '1st Lunch',
        startTime: '10:35',
        endTime: '11:10',
        length: '35 minutes',
        lunchId: 0,
    },
    {
        name: '2nd Lunch',
        startTime: '11:20',
        endTime: '11:55',
        length: '35 minutes',
        lunchId: 1,
    },
    {
        name: '3rd Lunch',
        startTime: '12:00',
        endTime: '12:35',
        length: '35 minutes',
        lunchId: 2,
    },
];

export const WeekByWeekSchedule: Record<number, ScheduleWeek> = {
    // May 17
    21: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '2:35',
                endTime: '3:05',
                isLunch: false,
                async: false,
                length: '30 minutes',
                blockNumber: 1,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '3:10',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:25',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:35',
                endTime: '12:35',
                isLunch: true,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
                lunchBlocks: wedLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '12:45',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:05',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
    // May 24
    22: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '2:35',
                endTime: '3:05',
                isLunch: false,
                async: false,
                length: '30 minutes',
                blockNumber: 1,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '3:10',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:25',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:35',
                endTime: '12:35',
                isLunch: true,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
                lunchBlocks: wedLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '12:45',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '2:05',
                endTime: '2:30',
                isLunch: false,
                async: false,
                length: '25 minutes',
                blockNumber: 2,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:40',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
    // May 31
    23: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'E Block/Tertulia',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block/Tertulia',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block/Tertulia',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community/Tertulia',
                blockType: 'community',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:25',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:35',
                endTime: '12:35',
                isLunch: true,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
                lunchBlocks: wedLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '12:45',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:05',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '12:05',
                endTime: '12:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '1:05',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '2:05',
                endTime: '2:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '3:05',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '12:05',
                endTime: '12:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '1:05',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '2:05',
                endTime: '2:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '3:05',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 3,
            },
        ],
    },
    // June 7
    24: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '2:35',
                endTime: '3:05',
                isLunch: false,
                async: false,
                length: '30 minutes',
                blockNumber: 1,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '3:10',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:25',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:35',
                endTime: '12:35',
                isLunch: true,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
                lunchBlocks: wedLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '12:45',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:05',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '9:50',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:00',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 3,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '10:45',
                endTime: '11:20',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '11:30',
                endTime: '12:05',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
    // June 14
    25: {
        monday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '2:35',
                endTime: '3:05',
                isLunch: false,
                async: false,
                length: '30 minutes',
                blockNumber: 1,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '3:10',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '70 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '1:30',
                endTime: '2:20',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 2,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '2:25',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
    // June 21
    26: {
        monday: [
            {
                name: 'Math and History Check-Ins',
                blockType: 'flex',
                startTime: '9:15',
                endTime: '10:00',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
            {
                name: 'Science and English Check-Ins',
                blockType: 'flex',
                startTime: '10:00',
                endTime: '10:45',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
            {
                name: 'All Other Check-Ins',
                blockType: 'flex',
                startTime: '10:45',
                endTime: '11:30',
                isLunch: false,
                async: false,
                length: '45 minutes',
                blockNumber: 1,
            },
        ],
        tuesday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '9:35',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '9:40',
                endTime: '10:00',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '10:05',
                endTime: '10:25',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '10:30',
                endTime: '10:50',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '10:55',
                endTime: '11:15',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '11:20',
                endTime: '11:40',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '11:45',
                endTime: '12:05',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
            {
                name: 'Community',
                blockType: 'community',
                startTime: '12:10',
                endTime: '12:30',
                isLunch: false,
                async: false,
                length: '20 minutes',
                blockNumber: 1,
            },
        ],
        wednesday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '9:50',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '9:55',
                endTime: '10:30',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '10:35',
                endTime: '11:10',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '11:15',
                endTime: '12:05',
                isLunch: false,
                async: false,
                length: '50 minutes',
                blockNumber: 2,
            },
            {
                name: 'Lunch',
                blockType: 'lunch',
                startTime: '12:05',
                endTime: '12:40',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'D Block Async',
                blockType: 'd',
                startTime: '12:40',
                endTime: '1:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'E Block Async',
                blockType: 'e',
                startTime: '1:20',
                endTime: '1:55',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'F Block Async',
                blockType: 'f',
                startTime: '2:00',
                endTime: '2:35',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
            {
                name: 'G Block Async',
                blockType: 'g',
                startTime: '2:40',
                endTime: '3:15',
                isLunch: false,
                async: false,
                length: '35 minutes',
                blockNumber: 2,
            },
        ],
        thursday: [
            {
                name: 'A Block',
                blockType: 'a',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'B Block',
                blockType: 'b',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'C Block',
                blockType: 'c',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'D Block',
                blockType: 'd',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
        friday: [
            {
                name: 'E Block',
                blockType: 'e',
                startTime: '9:15',
                endTime: '10:35',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'F Block',
                blockType: 'f',
                startTime: '10:45',
                endTime: '12:55',
                isLunch: true,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
                lunchBlocks: mttfLunches,
            },
            {
                name: 'G Block',
                blockType: 'g',
                startTime: '1:05',
                endTime: '2:25',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
            {
                name: 'Flex Block',
                blockType: 'flex',
                startTime: '2:35',
                endTime: '3:55',
                isLunch: false,
                async: false,
                length: '80 minutes',
                blockNumber: 3,
            },
        ],
    },
};

export const DummyEndOfYearSchedule: ScheduleWeek = {
    monday: [
        {
            name: 'A Block',
            blockType: 'a',
            startTime: '9:15',
            endTime: '10:35',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
        },
        {
            name: 'B Block',
            blockType: 'b',
            startTime: '10:45',
            endTime: '12:55',
            isLunch: true,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
            lunchBlocks: mttfLunches,
        },
        {
            name: 'C Block',
            blockType: 'c',
            startTime: '1:05',
            endTime: '2:25',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
        },
        {
            name: 'D Block',
            blockType: 'd',
            startTime: '2:35',
            endTime: '3:55',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
        },
    ],
    tuesday: [
        {
            name: 'E Block',
            blockType: 'e',
            startTime: '9:15',
            endTime: '10:35',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
        },
        {
            name: 'F Block',
            blockType: 'f',
            startTime: '10:45',
            endTime: '12:55',
            isLunch: true,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
            lunchBlocks: mttfLunches,
        },
        {
            name: 'G Block',
            blockType: 'g',
            startTime: '1:05',
            endTime: '2:25',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 1,
        },
        {
            name: 'Community',
            blockType: 'community',
            startTime: '2:35',
            endTime: '3:05',
            isLunch: false,
            async: false,
            length: '30 minutes',
            blockNumber: 1,
        },
        {
            name: 'Flex Block',
            blockType: 'flex',
            startTime: '3:10',
            endTime: '3:55',
            isLunch: false,
            async: false,
            length: '45 minutes',
            blockNumber: 1,
        },
    ],
    wednesday: [
        {
            name: 'A Block',
            blockType: 'a',
            startTime: '9:15',
            endTime: '9:50',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'B Block',
            blockType: 'b',
            startTime: '9:55',
            endTime: '10:30',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'C Block',
            blockType: 'c',
            startTime: '10:35',
            endTime: '11:10',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'Flex Block',
            blockType: 'flex',
            startTime: '11:15',
            endTime: '12:05',
            isLunch: false,
            async: false,
            length: '50 minutes',
            blockNumber: 2,
        },
        {
            name: 'Lunch',
            blockType: 'lunch',
            startTime: '12:05',
            endTime: '12:40',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'D Block Async',
            blockType: 'd',
            startTime: '12:40',
            endTime: '1:15',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'E Block Async',
            blockType: 'e',
            startTime: '1:20',
            endTime: '1:55',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'F Block Async',
            blockType: 'f',
            startTime: '2:00',
            endTime: '2:35',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
        {
            name: 'G Block Async',
            blockType: 'g',
            startTime: '2:40',
            endTime: '3:15',
            isLunch: false,
            async: false,
            length: '35 minutes',
            blockNumber: 2,
        },
    ],
    thursday: [
        {
            name: 'A Block',
            blockType: 'a',
            startTime: '9:15',
            endTime: '10:35',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
        {
            name: 'B Block',
            blockType: 'b',
            startTime: '10:45',
            endTime: '12:55',
            isLunch: true,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
            lunchBlocks: mttfLunches,
        },
        {
            name: 'C Block',
            blockType: 'c',
            startTime: '1:05',
            endTime: '2:25',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
        {
            name: 'D Block',
            blockType: 'd',
            startTime: '2:35',
            endTime: '3:55',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
    ],
    friday: [
        {
            name: 'E Block',
            blockType: 'e',
            startTime: '9:15',
            endTime: '10:35',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
        {
            name: 'F Block',
            blockType: 'f',
            startTime: '10:45',
            endTime: '12:55',
            isLunch: true,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
            lunchBlocks: mttfLunches,
        },
        {
            name: 'G Block',
            blockType: 'g',
            startTime: '1:05',
            endTime: '2:25',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
        {
            name: 'Flex Block',
            blockType: 'flex',
            startTime: '2:35',
            endTime: '3:55',
            isLunch: false,
            async: false,
            length: '80 minutes',
            blockNumber: 3,
        },
    ],
};
